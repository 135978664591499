<template>
  <form
    class="login-form"
    @submit.prevent="onSubmit"
  >
    <dx-form :disabled="loading">
      <dx-item :visible="showLoginFields">
        <div class="dx-field">
          <div class="">
            <DxTextBox 
              v-model:value="loginUser"
              placeholder="Enter username"
            />
          </div>
        </div>
      </dx-item>
      <dx-item :visible="showLoginFields">
        <div class="dx-field">
          <div class="">
            <DxTextBox 
              v-model:value="loginPass"
              :show-clear-button="true"
              mode="password"
              placeholder="Enter password"
            />
          </div>
        </div>
      </dx-item>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
          @click="onLoginClick(event)"
        />
      </dx-button-item>
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator
              v-if="loading"
              width="24px"
              height="24px"
              :visible="true"
            />
            <span v-if="!loading">Sign In</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, { DxButtonItem, DxItem, DxButtonOptions } from "devextreme-vue/form";
import DxTextBox from "devextreme-vue/text-box";
import notify from "devextreme/ui/notify";

import basicAuth from "../authsrc/basicAuth";
import b2cAuth from "../authsrc/b2cAuth";
import store from "../store";
import ConfigValues from "../utils/configValues";

import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    DxLoadIndicator,
    DxForm,
    DxItem,
    DxButtonItem,
    DxButtonOptions,
    DxTextBox
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loading = ref(false);

    async function onSubmit() {
      if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C") {
        loading.value = true;
        const result = await b2cAuth.logIn();
        if (!result.isOk) {
          loading.value = false;
          notify(result.message, "error", 3000);
        } else {
          router.push(route.query.redirect || "/home");
        }
      }
    }

    onMounted(() => {
      store.dispatch("setLoader", false);
    });

    return {
      loading,
      onSubmit,
      route,
      router
    };
  },

  data() {
    return {
      /** For BasicAuth when enabled, shows textbox for user+pass */
      showLoginFields: ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic",
      /** For BasicAuth when enabled */
      loginUser: null,
      /** For BasicAuth when enabled */
      loginPass: null
    }
  },

  methods: {
    onLoginClick() {
      if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic") {
        this.loading = true;
        basicAuth.performBasicAuthLogIn(this.loginUser, this.loginPass, "xyz-123-abc")
          .then((result) => {
            if (result === true) {
              this.router.push(this.route.query.redirect || "/home");
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.response && error.response.status === 401){
              notify("Authentication failed", "error", 3000);
            } else {
              notify(error.message, "error", 3000);
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    } 
  }
};
</script>

<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

  .dx-field {
    display: block;
  }
}
</style>
